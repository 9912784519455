import React from 'react';
import style from './AboutUs.module.css';
import AboutUsAside from './AboutUsAside/AboutUsAside';
import AboutUsContent from './AboutUsContent/AboutUsContent';

const AboutUs = () => {

  return (
    <div className={style.wrap}>
      <AboutUsContent />
      <AboutUsAside />
    </div>
  )
}

export default AboutUs;