import React from 'react';
import style from './GetUpButton.module.css';

const GetUpButton = ({elementToScroll}) => {

  const scrollTop = (elementToScroll) => {
    elementToScroll.scrollIntoView({behavior: "smooth"});
  }

  return (
    <button className={style.wrap} onClick={() => scrollTop(elementToScroll.current)}>
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnssvgjs="http://svgjs.com/svgjs" version="1.1" x="0" y="0" viewBox="0 0 407.436 407.436" xmlSpace="preserve">
        <polygon xmlns="http://www.w3.org/2000/svg" points="203.718,91.567 0,294.621 21.179,315.869 203.718,133.924 386.258,315.869 407.436,294.621" dataoriginal="#000000" />
      </svg>
    </button>
  )
}

export default GetUpButton;