import { useRef } from 'react';
import { Route } from 'react-router-dom';
import './App.css';
import Backgroung from './components/desing/Background/Background';
import GetUpButton from './components/elements/GetUpButton/GetUpButton';
import ModalBottom from './components/elements/ModalBottom/ModalBottom';
import Footer from './components/grids/Footer/Footer';
import Header from './components/grids/Header/Header';
import AboutUs from './components/pages/AboutUs/AboutUs';
import Contacts from './components/pages/Contacts/Contacts';
import HowItWork from './components/pages/HowItWork/HowItWork';
import MainPage from './components/pages/MainPage/MainPage';
import YourWishes from './components/pages/YourWishes/YourWishes';

function App() {
  const headerScroll = useRef();
  return (
    <div className="App" ref={headerScroll}>
      <Backgroung />
      <Header />
      <main>
        <div className="container">
          <Route exact path="/" render={() => <MainPage />} />
          <Route exact path="/how-it-works/" render={() => <HowItWork />} />
          <Route exact path="/your-wishes/" render={() => <YourWishes />} />
          <Route exact path="/contact-us/" render={() => <Contacts />} />
          <Route exact path="/aboutus/" render={() => <AboutUs />} />
        </div>
      </main>
      <Footer />
      <ModalBottom />
      <GetUpButton
        elementToScroll={headerScroll}
      />
    </div>
  );
}

export default App;
