import React from 'react';
import style from './LinkContact.module.css';

const LinkContact = ({ type, title }) => {
  let href = '';
  if (type === 'phone') href = `tel:${title.replace(/[^\d;]/g, '')}`;
  if (type === 'mail') href = `mailto:${title}`;
  return (
    <a className={style.link} href={href}>{title}</a>
  )
}

export default LinkContact;