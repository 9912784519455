import React from 'react';
import style from './CardInfo.module.css';
import TitleSecond from '../TitleSecond/TitleSecond';
import SubtitleSecond from '../SubtitleSecond/SubtitleSecond';
import plusImg from './img/plus.png';
import { Link } from 'react-router-dom';

const CardInfo = ({ imgUrl, title, subtitle, content, url, secontInfo, secondUrl, secondImageUrl, secondTitle, secondSubtitle, secondContent }) => {

  const secondLink = (
    <>
      <img src={plusImg} alt="" />
      <Link to={secondUrl} className={style.container}>
        <div className={style.icon}>
          <img src={secondImageUrl} alt={title} />
        </div>
        <TitleSecond
          title={secondTitle}
          letterSpace={'3px'}
          position={'center'}
        />
        <SubtitleSecond
          title={secondSubtitle}
          position={'center'}
        />
        <div className={style.content}>
          <p>{secondContent}</p>
        </div>
      </Link>
    </>
  );

  return (
    <div className={style.wrap}>
      <Link to={url} className={style.container}>
        <div className={style.icon}>
          <img src={imgUrl} alt={title} />
        </div>
        <TitleSecond
          title={title}
          letterSpace={'3px'}
          position={'center'}
        />
        <SubtitleSecond
          title={subtitle}
          position={'center'}
        />
        <div className={style.content}>
          <p>{content}</p>
        </div>
      </Link>
      {secontInfo ? secondLink : null}
    </div>
  )
}

export default CardInfo;