import React from 'react';
import { Link } from 'react-router-dom';
import CardNews from '../../../elements/CardNews/CardNews';
import Title from '../../../elements/Title/Title';
import style from './AboutUsAside.module.css';

const AboutUsAside = () => {

  return (
    <aside className={style.wrap}>
      <Title
        title={'Lastest news'}
        position={'center'}
      />
      <div className={style.newsList}>
        <CardNews
          title={'AETERNUM’S SECOND MISSION BEGINS ITS 5 YEAR SPACE MEMORIAL FLIGHT ORBITING EARTH'}
          date={'july 21, 2021'}
          url={'#'}
        />
        <CardNews
          title={'AETERNUM’S SECOND LAUNCH CONFIRMED ON SPACEX ROCKET'}
          date={'May 26, 2021'}
          url={'#'}
        />
        <CardNews
          title={'AETERNUM SEES ITS MAIDEN SPACE MEMORIAL ROCKET LAUNCH'}
          date={'July 7, 2020'}
          url={'#'}
        />
        <CardNews
          title={'AETERNUM PREPARES FOR LAUNCH OF EUROPE’S FIRST MEMORIAL SPACECRAFT'}
          date={'July 3, 2020'}
          url={'#'}
        />
      </div>
      <Link to={'/news/'} className={style.link}>See All News...</Link>  
    </aside>
  )
}

export default AboutUsAside;