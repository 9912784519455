import React from 'react';
import Button from '../../ui/Button/Button';
import Input from '../../ui/Input/Input';
import style from './NewsLetter.module.css';

const NewsLetter = () => {

  return (
    <form className={style.wrap}>
      <Input type={'text'} placeholder={'Sign up to our newsletter'} />
      <Button title={'Sign Up'} />
    </form>
  )
}

export default NewsLetter;