import React from 'react';
import { useState } from 'react';
import Button from '../../ui/Button/Button';
import LinkA from '../../ui/LinkA/LinkA';
import style from './ModalBottom.module.css';

const ModalBottom = () => {

  let [isClose, setIsClose] = useState(false);

  const closeModal = () => {
    setIsClose(!isClose)
  }

  return (
    <div className={`${style.wrap} ${isClose && style.hide}`}>
      <div className={style.contentWrap}>
        <div className={style.content}>
          <span>We use necessary cookies to make our site work. We'd also be enormously grateful if you’d accept our use of optional cookies to help us improve it. Your can learn more about the use of cookies on our website
            <LinkA title={'Cookie Policy'} link={'#'} />
          </span>
        </div>
        <div className={style.buttons}>
          <Button
            onClick={closeModal}
            title={'Allow'}
          />
          <Button
            onClick={closeModal}
            title={'Disable'}
          />
        </div>
      </div>
    </div>
  )
}

export default ModalBottom;