import React from 'react';
import FirstContent from './FirstContent/FirstContent';
import style from './MainPage.module.css'
import SecondContent from './SecondContent/SecondContent';

const MainPage = () => {

  return (
    <div className={style.wrap}>
      <FirstContent />
      <SecondContent />
    </div>
  )
}

export default MainPage;