import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import WhiteLine from '../../desing/WhiteLine/WhiteLine';
import UserMenu from '../../elements/UserMenu/UserMenu';
import style from './Header.module.css';
import logoImg from './img/logo.png';
import MenuMain from './MenuMain/MenuMain';

const Header = () => {

  let [openMenu, setOpenMenu] = useState(false);

  const menuButtonHandle = (event) => {
    setOpenMenu(!openMenu);
  }

  return (
    <header className={style.wrap}>
      <UserMenu
        isMenuOpen={openMenu}
        onClick={menuButtonHandle}
      />
      <div className={style.logo}>
        <Link className={style.linkLogo} to={'/'}>
          <img src={logoImg} alt="Aeternum Space" />
        </Link>
      </div>
      <WhiteLine />
      <MenuMain
        isMenuOpen={openMenu}
      />
    </header>
  )
}

export default Header;