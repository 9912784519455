import Title from '../Title/Title';
import style from './ContentTextPart.module.css';

const ContentTextPart = ({ title, content }) => {
  return (
    <div className={style.wrap}>
      <Title
        title={title}
        position={'center'}
      />
      <div className={style.content}>
        {content}
      </div>
    </div>
  )
}

export default ContentTextPart;