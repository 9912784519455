import React from 'react';
import ContentTextPart from '../../../elements/ContentTextPart/ContentTextPart';
import style from './AboutUsContent.module.css';

const AboutUsContent = () => {

  return (
    <div className={style.wrap}>
      <ContentTextPart 
        title={'OUR STORY'}
        content={'Registered and based in the UK, Aeternum Limited has been developing ways by which all members of the public can participate in space missions. Our team has over 50 years of combined, ongoing experience in the space industry, including a further 100 plus years of combined experience from our partners, Commercial Space Technologies Limited (CST), in arranging rocket launches for commercial satellites (for more information on CST please visit www.commercialspace.co.uk). With this heritage in organising space launches, we aim to extend the space experience to those both in and outside of the space industry. We came to the conclusion that it was best to leave it to the individual to decide. Our spacecraft are simple in design but powerful in potential; comprised of customisable digital storage devices and capsules for cremated ash. Thus as an Aeternum customer, you get to choose the purpose and specifics of your mission, be it as a symbolic means to honour a loved one’s memory, or as a time capsule to preserve your legacy and/or that of your friends or family.'}
      />
      <ContentTextPart 
        title={'OUR SERVICE'}
        content={'You can use our spacecraft as a unique way to honour the life of a loved one, by launching a portion of their ashes on a return journey around the Earth. Conversely, you can also be part of a mission to one of the highest altitude orbits available, with the potential of never returning and far outlasting any trace of your life back on Earth. This service aims to operate as a time capsule, preserving not only the ashes of loved ones, but also whatever digital information people wish to upload. A variable amount of digital storage can be purchased on the spacecraft for any purpose desired, such as a gift, part of a memorial or just for you. There is even the option to have a message engraved on one of the outer panels of the spacecraft. Such messages will be located alongside engraved instructions on how to read the data, should any future generations rediscover the spacecraft. However you choose to be a part of our missions, you will be kept informed on its progress via regular updates, both personal though your account or email, or general though our website. A live video feed of the launch will also be available on our website. After launch, the journey doesn’t stop. You will be able to trace the exact location of the spacecraft over the Earth, in real time, with the Aeternum Satellite Tracking service'}
      />
    </div>
  )
}

export default AboutUsContent;