import React from 'react';
import style from './Subtitle.module.css';

const Subtitle = ({title, position}) => {

  return (
    <h2 className={style.title} style={{textAlign: position}}>{title}</h2>
  )
}

export default Subtitle;