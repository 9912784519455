import React from 'react';
import NewsLetter from '../../elements/NewsLetter/NewsLetter';
import SocialBlock from '../../elements/SocialBlock/SocialBlock';
import TitleSecond from '../../elements/TitleSecond/TitleSecond';
import style from './Footer.module.css';
import MenuFooter from './MenuFooter/MenuFooter';


const Footer = () => {

  return (
    <footer className={style.wrap}>
      <div className={style.container}>
        <div className={style.footerCols}>
          <div className={style.footerCol}>
            <TitleSecond
              title={'Quick Link'}
              position={'center'}
              letterSpace={0}
            />
            <MenuFooter />
          </div>
          <div className={style.footerCol}>
            <TitleSecond
              title={'Contact'}
              position={'center'}
              letterSpace={0}
            />
            <span>
              <p>+44 (0)208 566 7934</p>
              <p>(Business Hours: 9am-5pm Weekdays)</p>
              <p>-</p>
              <p>enquiries@aeternum.space</p>
            </span>
            <SocialBlock />
          </div>
          <div className={style.footerCol}>
            <TitleSecond
              title={'Newsletter'}
              position={'center'}
              letterSpace={0}
            />
            <NewsLetter />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;