import React from 'react';
import style from './Title.module.css';

const Title = ({title, position}) => {

  return (
    <h1 className={style.title} style={{textAlign: position}}>{title}</h1>
  )
}

export default Title;