import React from 'react';
import style from './Input.module.css';

const Input = ({ type, placeholder, id, name, error, touched, value, onChange }) => {
  return (
    <div className={style.wrap}>
      <input
        type={type}
        placeholder={placeholder}
        id={id}
        name={name}
        className={style.input}
        value={value}
        onChange={onChange}
      />
      {touched && error ? (
        <span className={style.error}>{error}</span>
      ) : null}
    </div>
  )
}

export default Input;