import React from 'react';
import ButtonMenuMobile from '../../ui/ButtonMenuMobile/ButtonMenuMobile';
import CartLink from './CartLink/CartLink';
import { useMediaQuery } from 'react-responsive'
import style from './UserMenu.module.css';

const UserMenu = ({onClick, isMenuOpen}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' })
  return (
    <div className={style.wrap}>
      <CartLink />
      {isMobile && <ButtonMenuMobile 
        onClick={onClick}
        isMenuOpen={isMenuOpen}
      />}      
    </div>
  )
}

export default UserMenu;