import React from 'react';
import style from './FormContact.module.css';
import { useFormik } from 'formik';
import Input from '../../ui/Input/Input';
import Textarea from '../../ui/Textarea/Textarea';
import Button from '../../ui/Button/Button';
import Radio from '../../ui/Radio/Radio';

const FormContact = () => {

  const formik = useFormik({

    initialValues: {
      name: '',
      mail: '',
      phone: '',
      message: '',
    },
    validate,
    // handleChange,
    // handleBlur
    // onSubmit: values => {
    //   dispatch(setFormContactDataThunk(values))
    // },
  });

  return (
    <form className={style.wrap} onSubmit={formik.handleSubmit}>
      <div className={style.formRow}>
        <Input
          type={'text'}
          name={'name'}
          id={'name'}
          placeholder={'Your Name'}
          // onBlur={form}
          onChange={formik.handleChange}
          error={formik.errors.name}
          values={formik.values.name}
          touched={formik.touched.name}
        />
        <Input
          type={'text'}
          name={'mail'}
          id={'mail'}
          placeholder={'Your Email'}
          onChange={formik.handleChange}
          error={formik.errors.mail}
          values={formik.values.mail}
          touched={formik.touched.mail}
        />
      </div>
      <div className={style.formRow}>
        <Input
          type={'text'}
          name={'phone'}
          id={'phone'}
          placeholder={'Phone Number (Optional)'}
          onChange={formik.handleChange}
          error={formik.errors.phone}
          values={formik.values.phone}
          touched={formik.touched.phone}
        />
        <Radio
          label={'Preferred method of contact:'}
          itemsArray={[
            {
              id: 'phone',
              name: 'contactType',
              value: 'phone',
              label: 'Phone',
              checked: true
            },
            {
              id: 'mail',
              name: 'contactType',
              value: 'mail',
              label: 'eMail',
              checked: false
            }
          ]}
          error={formik.errors.contactType}
        />
      </div>
      <Textarea
        name={'message'}
        id={'message'}
        placeholder={'Message here'}
      />
      <Button
        title={'Submit'}
        type={'submit'}
      />
    </form>
  )
}

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'This field requared';
  }
  if (!values.mail) {
    errors.mail = 'This field requared';
  }
  if (!/^((\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/i.test(values.phone)) {
    errors.phone = 'Please enter correct phone number';
  }
  return errors;
}

export default FormContact;