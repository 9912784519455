import React from 'react';
import style from './FirstContent.module.css'

const FirstContent = () => {

  return (
    <div className={style.wrap}>
      <p>
        Immortalise the legacy of you or a loved one and embrace the timeless, infinite expanse of space with Aeternum’s unique memorial service. Our carbon-neutral space burial service is easy to arrange, and our satellite tracking allows families to follow their loved one’s journey through the stars.
      </p>
      <p>
        If you’re interested in sending ashes into space or any of our other bespoke memorial services, please get in touch with Aeternum today.
      </p>
    </div>
  )
}

export default FirstContent;