import React from 'react';
import style from './TitleSecond.module.css';

const TitleSecond = ({title, letterSpace, position}) => {

  return (
    <h3 className={style.title} style={{letterSpacing: letterSpace, textAlign: position}}>{title}</h3>
  )
}

export default TitleSecond;