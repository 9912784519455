import React from 'react';
import Subtitle from '../../elements/Subtitle/Subtitle';
import Title from '../../elements/Title/Title';
import style from './YourWishes.module.css';
import image from './img/image.png';

const YourWishes = () => {

  return (
    <div className={style.wrap}>
      <div className="titleBlock">
        <Title
          title={'YOUR WISHES'}
          position={'center'}
        />
        <Subtitle
          title={'WHERE NEXT?'}
          position={'center'}
        />
      </div>
      <img src={image} alt={'YOUR WISHES'} />
      <div className={style.content}>
        <p>
          Should you personally wish to be remembered through one of our services, this page includes useful information to help you plan your legacy.
          Under the present law in the UK, the executors appointed under the will have ownership of the body and are, technically, the decision makers for funeral arrangements. Funeral wishes contained in wills are, unfortunately, not legally binding on the executors for the time being.
          The best solution for ensuring that your wishes are carried through are therefore as follows:
          Have a conversation about your wishes with appropriate members of your family, friends, or whoever you may be considering to appoint as executors of your will. While this may be a difficult conversation, making sure that those ultimately responsible know your wishes, and just as importantly, understand them, will be the best way to ensure that they are realised.
          Download and complete our Aeternum Space Statement of Wishes document and leave it with your Will for your executor(s) to find. Alternatively, you may ask your solicitor to include these instructions in your Will itself.
          You may also consider setting some funds aside for your chosen legacy in a trust account.
          Should you require any assistance with planning your legacy with us, or should you require any more information about our services, please contact us at enquiries@aeternum.space and one of our agents will be in touch shortly.
        </p>
      </div>
    </div>
  )
}

export default YourWishes;