import React from 'react';
import { NavLink } from 'react-router-dom';
import style from './MenuMain.module.css';

const MenuMain = ({isMenuOpen}) => {

  return (
    <nav className={`${style.wrap} ${isMenuOpen && style.active}`}>
      <ul>
        <li><NavLink exact activeclassname={style.selected} to="/" >Services</NavLink></li>
        <li><NavLink activeclassname={style.selected} to="/how-it-works/">How it Works</NavLink></li>
        <li><NavLink activeclassname={style.selected} to="/aboutus/">About Us</NavLink></li>
        <li><NavLink activeclassname={style.selected} to="/your-wishes/">Your Wishes</NavLink></li>
        <li><NavLink activeclassname={style.selected} to="/contact-us/">Contact</NavLink></li>
      </ul>
    </nav>
    )
}

export default MenuMain;