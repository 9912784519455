import React from 'react';
import style from './Backgroung.module.css';
import bcgImage from './img/main_bg.jpg';

const Backgroung = () => {  

  return (
    <div className={style.wrap}>
      <img className={style.image} src={bcgImage} alt="" />
    </div>
  )
}

export default Backgroung;