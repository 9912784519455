import React from 'react';
import style from './SubtitleSecond.module.css';

const SubtitleSecond = ({title, position}) => {

  return (
    <h5 className={style.title} style={{textAlign: position}}>{title}</h5>
  )
}

export default SubtitleSecond;