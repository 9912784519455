import React from 'react';
import style from './Radio.module.css';

const Radio = ({ label, itemsArray }) => {

  const radios = [...itemsArray].map((item, i) => <label key={i} className={style.label}>
    <input id={item.id} type="radio" name={item.name} value={item.value} />
    <span></span>
    {item.label}
  </label>
  )

  return (
    <div className={style.wrap}>
      <span>{label}</span>
      <div className={style.items}>
        {radios}
      </div>
    </div>
  )
}

export default Radio;