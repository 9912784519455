import React from 'react';
import style from './SocialBlock.module.css';
import SocialIcon from './SocialIcon/SocialIcon';

const SocialBlock = () => {

  return (
    <div className={style.wrap}>
      <SocialIcon icon={'facebook'} link={'#'}/>
      <SocialIcon icon={'instagram'} link={'#'} />
      <SocialIcon icon={'twitter'} link={'#'} />
      <SocialIcon icon={'youtube'} link={'#'} />
    </div>
  )
}

export default SocialBlock;