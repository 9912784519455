import React from 'react';
import style from './CardNews.module.css';
import { Link } from 'react-router-dom';

const CardNews = ({ title, date, url }) => {
  return (
    <Link to={url} className={style.wrap}>
      <h4>{title}</h4>
      <span>{date}</span>
    </Link>
  )
}

export default CardNews;