import React, { useState } from 'react';
import Title from '../Title/Title';
import style from './Accordion.module.css';

const Accordion = ({ title, content, isOpen}) => {
  const [open, setOpen] = useState(isOpen);

  return (
    <div className={`${style.wrap} ${open && style.open}`}>
      <div className={style.titleBlock} onClick={() => setOpen(!open)}>
        <Title
          title={title}
          position={'center'}
        />
      </div>
      <div className={style.contentBlock}>
        <p>{content}</p>
      </div>
    </div>
  )
}

export default Accordion;