import React from 'react';
import style from './Button.module.css';

const Button = ({ title, type, onClick }) => {

  return (
    <button type={type} onClick={onClick} className={style.button}>{title}</button>
  )
}

export default Button;