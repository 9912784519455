import React from 'react';
import { NavLink } from 'react-router-dom';
import style from './MenuFooter.module.css';


const MenuFooter = () => {

  return (
    <nav className={style.wrap}>
      <ul>
        <li><NavLink to="/faqs/" activeclass={style.selected}>FAQs</NavLink></li>
        <li><NavLink to="/terms-of-website-use/">Terms of website use</NavLink></li>
        <li><NavLink to="/privacy-policy/">Privacy Policy</NavLink></li>
        <li><NavLink to="/cookie-policy/">Cookie Policy</NavLink></li>
        <li><NavLink to="/terms-and-conditions/">Terms of Service</NavLink></li>
        <li><NavLink to="/aboutus/">AboutUs</NavLink></li>
        <li><NavLink to="/news/">News</NavLink></li>
      </ul>
    </nav>
  )
}

export default MenuFooter;