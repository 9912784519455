import thunkMiddleware from "redux-thunk";

const { createStore, combineReducers, applyMiddleware } = require("redux");

let reducers = combineReducers({
});

let store = createStore(reducers, applyMiddleware(thunkMiddleware));

window.store = store;
export default store;