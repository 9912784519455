import React from 'react';
import { Link } from 'react-router-dom';
import style from './LinkA.module.css';

const LinkA = ({ title, link }) => {

  return (
    <Link className={style.link} to={link}>{title}</Link>
  )
}

export default LinkA;