import React from 'react';
import CardInfo from '../../../elements/CardInfo/CardInfo';
import style from './SecondContent.module.css'
import leoIcon from '../../../../assets/img/logos/leo.png';
import geoIcon from '../../../../assets/img/logos/geo.png';
import bespokeIcon from '../../../../assets/img/logos/bespoke.png';
import geoDataIcon from '../../../../assets/img/logos/geo_data.png';

const SecondContent = () => {

  return (
    <div className={style.wrap}>
      <CardInfo
        url={'/product/shooting-star/'}
        imgUrl={leoIcon}
        title={'shooting star'}
        subtitle={'short term orbit'}
        content={'Honour the memory of your loved one by sending a sample of their ashes to space in a specially designed and custom engraved capsule. You will be able to track their journey in real time on our Satellite Tracking Service as they orbit the Earth and reenter the atmosphere, appearing as a shooting star.'}
      />
      <CardInfo
        url={'/product/space-ark/'}
        imgUrl={geoIcon}
        title={'space ark'}
        subtitle={'indefinite orbit'}
        content={'Have your loved one remembered for all time by launching a sample of their ashes with an attached micro SD card of their life story into space, on a spacecraft that will orbit our planet indefinitely. You and future generations will be able to follow their peaceful, undisturbed journey around the Earth on our Satellite Tracking service.'}
        secontInfo={true}
        secondUrl={'/product/data-only/'}
        secondImageUrl={geoDataIcon}
        secondTitle={'data only'}
        secondSubtitle={'Legacy service'}
        secondContent={'Upload your life story in the form of text, photos and videos, to be stored in the data-banks of our spacecraft. Track your lasting legacy as it orbits the Earth indefinitely. Priced at only £40 per gigabyte!'}
      />
      <CardInfo
        url={'/product/bespoke/'}
        imgUrl={bespokeIcon}
        title={'bespoke'}
        subtitle={'Design your journey'}
        content={'Have ideas for your own space mission? We can work with you to design and launch something uniquely personal into space, be it in orbit around the Earth, to the moon or even beyond.'}
      />
    </div>
  )
}

export default SecondContent;