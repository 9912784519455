import React from 'react';
import Title from '../../elements/Title/Title';
import FormContact from '../../forms/FormContact/FormContact';
import LinkContact from '../../ui/LinkContact/LinkContact';
import style from './Contacts.module.css';

const Contacts = () => {

  return (
    <div className={style.wrap}>
      <div className="titleBlock">
        <Title
          title={'CONTACT PAGE'}
          position={'center'}
        />
      </div>
      <div className={style.content}>
        <div className={style.description}>
          <p>
            Our normal operating hours are 9am – 5pm Greenwich Mean Time or British Summer Time, Monday to Friday. Please feel free to contact us outside of operating hours and we will aim to reply within one business day.
          </p>
          <p>
            We observe the following UK national holidays: New Year’s Day; Good Friday; Easter Monday; Early May Bank Holiday; Spring Bank Holiday; Summer Bank Holiday; Christmas Bank Holiday; and Boxing Day.
          </p>
        </div>
        <div className={style.contacts}>
          <LinkContact
            type={'mail'}
            title={'enquiries@aeternum.space'}
          />
          <LinkContact
            type={'phone'}
            title={'+44 (0)208 566 7934'}
          />
          <span>
            67 Shakespeare Road
            London, W7 1LU
          </span>
        </div>
      </div>
      <FormContact
      
      />
      
    </div>
  )
}

export default Contacts;