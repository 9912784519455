import React from 'react';
import style from './Textarea.module.css';

const Textarea = ({ placeholder, id, name }) => {

  return (
    <textarea className={style.textarea} name={name} id={id} placeholder={placeholder}></textarea>
  )
}

export default Textarea;