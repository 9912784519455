import React from 'react';
import style from './WhiteLine.module.css';


const WhiteLine = () => {  

  return (
    <div className={style.line}></div>
  )
}

export default WhiteLine;